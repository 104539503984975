import axios from "axios";
import { toast } from "react-toastify";
export const baseURL = process.env.REACT_APP_MAIN_URL;
const SECONDS = 30;
const MILISECONDS = 1000;
const timeout = SECONDS * MILISECONDS;
const TOKEN_PAYLOAD_KEY = "authorization";

const SERVER = axios.create({
	baseURL,
	// timeout,
});

SERVER.interceptors.request.use(function (config) {
	const TOKEN = localStorage.getItem("token");
	if (TOKEN) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${TOKEN}`;
	}
	return config;
});

SERVER.interceptors.response.use(
	(response) => {
		const result = Promise.resolve(response);
		if (result) {
			// SnackBar(true, 'Helo')
			return Promise.resolve(response);
		}
	},
	(error) => {
		// if (error?.response?.status === 400) {
		// 	toast.error(error?.response?.data?.message);
		// 	return Promise.reject(error.response.data);
		// }
		if (error?.response?.status === 401) {
			toast.error("Session Closed", "Session closed ....you have been logged out!");
			window.location.href = "/";
			return Promise.reject(error.response.data);
		}
		if (error?.response?.status === 403) {
			toast.error(
				"Authentication Fail",
				"Authentication Fail you are need to verify your login"
			);
			return Promise.reject(error.response.data);
		}
		// if (error?.response?.status === 404) {
		// 	toast.error("Not Found", "You are looking page is not available ...!");
		// 	return Promise.reject(error.response.data);
		// }
		// if (error?.response?.status === 500) {
		// 	toast.error("Internal Server Error", "Code error in API ....pls look back");
		// 	return Promise.reject(error.response.data);
		// }
		// if (error?.response?.status === 508) {
		// 	toast.error("Time Out", "Your token was expired ....please verify that");
		// 	return Promise.reject(error.response.data);
		// }
		else if (error.response) {
			return Promise.reject(error.response.data);
		}
		// else {
		// 	return Promise.reject(error);
		// }
	}
);

export default SERVER;
